import React from "react"
import { graphql, navigate } from "gatsby"
import { RichText } from "prismic-reactjs"
import Img from "gatsby-image"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Layout from "components/layout"
import Section from "components/section"
import Pattern from "components/pattern"
import SectionFocusPoints from "sections/focus-points"
import RichTextWrapper from "components/richTextWrapper"
import SEO from "components/seo"

import SectionAboutMe from "sections/about-me"

import style from "./index.module.scss"

const IndexPage = ({data}) => {

  const node = data.allPrismicHome.edges[0].node.data;
  const slice_edges = data.allPrismicHomeBodySchwerpunkt.edges;

  const pattern = data.pattern.childImageSharp.fluid;

  return (
    <Layout>
      <SEO
        title="Startseite"
        description="In meiner psychotherapeutischen Praxis helfe ich ihnen in belastenden Lebenssituationen neue Sichtweisen zu entwickeln und den Weg für neue Perspektiven zu ebnen."
        canonical
        canonicalUri=""
      />
      <Section
        className={style.atf}
        hasMargin={false}
        hasBackground={true}
        hasPadding={true}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} md={7}>
            <p className={style.claim}>
              {node.claim.text}
            </p>
          </Grid>
          <Grid item xs={12} md={7}>
            <h1 className={style.subClaim}>
              {node.sub_claim.text}
            </h1>
          </Grid>
        </Grid>
      </Section>
      <Img
        className={style.pattern1}
        fluid={pattern}
      />
      <SectionAboutMe
        image={node.about_me_image.localFile.childImageSharp.fluid}
      >
        <h2>
          {node.about_me_heading.text}
        </h2>
        <RichText
          render={node.about_me_text.raw}
        />
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={style.aboutMeButton}
          onClick={() => navigate("/ueber-mich/")}
        >
          Mehr über mich
        </Button>
      </SectionAboutMe>

      <Pattern
        color="grey"
        size="md"
        offset="sm"
        position="right"
      />

      <Section
        hasMargin={true}
        hasBackground={true}
        hasPadding={true}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} lg={5}>
            <h2>
              {node.psychotherapy_heading.text}
            </h2>
          </Grid>
        </Grid>
        <RichTextWrapper>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={7}>
              <RichText
                render={node.psychotherapy_text_1.raw}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <RichText
                render={node.psychotherapy_text_2.raw}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RichText
                render={node.psychotherapy_text_3.raw}
              />
            </Grid>
          </Grid>
        </RichTextWrapper>
      </Section>

      <Pattern
        color="yellow"
        size="md"
        offset="xl"
        position="left"
      />

      <Section
        containerClass={style.center}
        hasMargin={true}
      >
        <div>
          <div className="text-center">
            <h2>
              {node.contact_cta_heading.text}
            </h2>
          </div>
          <Grid container justify="center">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => navigate("/kontakt/")}
            >
              {node.contact_cta_button_text.text}
            </Button>
          </Grid>
        </div>
      </Section>

      <Pattern
        color="grey"
        size="sm"
        offset="sm"
        position="right"
      />

      <SectionFocusPoints
        heading={node.focus_heading.text}
        edges={slice_edges}
      />

    </Layout>
  )
}


export const query = graphql`
  query HomeQuery {
    allPrismicHome {
      edges {
        node {
          data {

            claim {
              html
              text
              raw
            }
            sub_claim {
              html
              text
              raw
            }

            about_me_image {
              localFile {
                childImageSharp {
                  fluid (maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }

            about_me_heading {
              html
              text
              raw
            }
            about_me_text {
              html
              text
              raw
            }

            psychotherapy_heading {
              html
              text
              raw
            }
            psychotherapy_text_1 {
              html
              text
              raw
            }
            psychotherapy_text_2 {
              html
              text
              raw
            }
            psychotherapy_text_3 {
              html
              text
              raw
            }

            contact_cta_heading {
              html
              text
              raw
            }
            contact_cta_button_text {
              html
              text
              raw
            }
            contact_cta_button_link {
              type
            }

            focus_heading {
              html
              text
              raw
            }
          }
        }
      }
    }

    allPrismicHomeBodySchwerpunkt {
      edges {
        node {
          items {
            focus_title {
              html
              text
              raw
            }
          }
        }
      }
    }

    pattern: file(relativePath: {eq: "pattern.png"}) {
      childImageSharp {
        fluid (maxWidth: 250) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    patternGrey: file(relativePath: {eq: "pattern-grey.png"}) {
      childImageSharp {
        fluid (maxWidth: 250) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
