import React from "react"
import PropTypes from "prop-types"

import style from "./index.module.scss";

const RichTextWrapper = ({ children }) => (
  <div className={style.richTextWrapper}>
    {children}
  </div>
)

RichTextWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RichTextWrapper
