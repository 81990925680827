import React from "react"
import { RichText } from "prismic-reactjs"
import Grid from "@material-ui/core/Grid"
import Section from "components/section"

import style from "./index.module.scss"

const SectionFocusPoints = ( {heading, edges} ) => {

  const rendered_items = edges[0].node.items.map( (item, index) => (
      <Grid item xs={12} md={4} key={index} className={style.item}>
        <RichText
          render={item.focus_title.raw}
        />
      </Grid>
    )
  )

  return (
    <Section
      hasMargin={true}
      hasBackground={true}
      hasPadding={true}
    >
      <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={style.heading}>
            <h2>
              {heading}
            </h2>
          </Grid>

        {rendered_items}
      </Grid>
    </Section>
  )
}

export default SectionFocusPoints
